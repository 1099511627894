<template>
    <vue-my-multi-select
        :name="selectedName"
        :options="options"
        :values="values"
        :placeholder="'Selecione as tags'"
        :values-map="mapInitialOptions"
        :track-by="'text'">
        <template v-slot:new-tags-input="props" >
            <input v-for="(option, index) in props.selectedNewOptions" :key="`${name}__nonxistent__${index}`" type="hidden" :name="`${name}[${index}][name]`" :value="option.text">
        </template>
        <template slot="noOptions">
            Nenhuma Tag existente
        </template>
    </vue-my-multi-select>

</template>

<script>
export default {
    props:{
        name: {
            type: String,
            default: 'catalog[tags_attributes]'
        },
        placeholder: String,
        selectedName: {
            type: String,
            default: 'catalog[tag_ids][]'
        },
        values:{
            type: Array,
            default: ()=>[]
        },
        options: {
            type: Array,
            default: ()=>[]
        }
    },
    methods: {
        mapInitialOptions(option){
            return {text: option.title, value: option.id}
        },
        onAddTag(newTagData, selectedOptions){
            this.$apiService.createTag({
                tagData: {title: newTagData, tenant_id: this.meet.tenant_id},
                onSucess: (response)=>{
                    var _newTag = {
                        text: response.data.title,
                        value: response.data.id,
                        new: false
                    }
                    this.addTag(_newTag)
                    selectedOptions.push(_newTag);
                }
            })
        },
    }
}
</script>