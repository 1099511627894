<script>
export default {
    data(){
        return {
            addressType: '',
        }
    },
    props:{
        initialAddressType: 'company_address'
    },
    beforeMount(){
        this.addressType = this.initialAddressType
    }  
}
</script>