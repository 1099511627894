import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';

// Import Plugins
import MainPlugin from '../../javascripts/plugins/main';

// Import Third Partie Libs
import VueTheMask from 'vue-the-mask';
import money from 'v-money';

// Import Indexes
import '../../images/index.js.erb';
import '../../javascripts/components/index.js.erb';

Vue.use(TurbolinksAdapter);
Vue.use(MainPlugin);

// Register Third Parties Libs
Vue.use(VueTheMask);
Vue.use(money, {precision:2});

document.addEventListener('turbolinks:load', () => {
    const element = document.getElementById('appAdmin');
    if (element != null) {
        const app = new Vue({
            el: '#appAdmin',
        });
        console.log('Loaded Vue');
    }
})
