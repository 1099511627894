<script>
export default {
    data(){
        return {
            representationType: '',
            representativeSecond: false,
        }
    },
    props:{
        initialRepresentationType: 'user_responsible'
    },
    beforeMount(){
        this.representationType = this.initialRepresentationType
    } ,

    methods:{
        representativeSecond(event){
            this.representativeSecond = true;
        },



    }

    
}
</script>