<template>
    <div class="my-multiselect-container">
        <slot name="selected-options" :optionsSelected="selectedPreExistentOptions" :inputName="mySelectedName">
            <input v-for="(option, index) in selectedPreExistentOptions" :key="`${mySelectedName}__prexistent__${index}`" type="hidden" :name="mySelectedName" :id="id" :value="option.value">
        </slot>
        <slot name="new-tags-input"  :selectedNewOptions="selectedNewOptions" :inputName="name"></slot>
        <vue-multiselect
            v-model="optionsSelected"
            :options="options"
            :placeholder="placeholder"
            :select-label="'Selecionar'"
            :deselect-label="'Remover'"
            :selected-label="'Selecionado'"
            :tagPlaceholder="'Aperte Enter para criar a Tag'"
            :track-by="trackBy"
            :allow-empty="true"
            :close-on-select="false"
            :searchable="searchable"
            :multiple="true"
            :taggable="taggable"
            :custom-label="customLabel"
            :group-select="groupSelect"
            :group-values="groupValues"
            :group-label="groupLabel"
            @select="onSelect"
            @remove="myOnRemove"
            @tag="defaultOnAddTag"
        >
            <template slot="noOptions">
                <slot name="noOptions"></slot>
            </template>
        </vue-multiselect>
    </div>
</template>


<script>
import Multiselect from 'vue-multiselect'

function defaultCustomLabel(option){
    return option.text
}

export default {
    components: { 'vue-multiselect': Multiselect },
    props:{
        name: String | Function,
        selectedName: {
            type: String,
            default: null
        },
        id: String,
        values:{
            type: Array,
            default: ()=>[]
        },
        options: {
            type: Array,
            default: ()=>[]
        },
        placeholder: {
            type: String,
            default: 'Selecione uma opção'
        },
        trackBy: {
            type: String,
            default: 'value'
        },
        customLabel: {
            type: Function,
            default: defaultCustomLabel
        },
        onAddTag: {
            type: Function,
            default: null
        },
        onSelect: {
            type: Function,
            default: (option, id)=>{}
        },
        onRemove: {
            type: Function,
            default: null
        },
        valuesMap: {
            type: Function,
            default: null
        },
        taggable: {
            type: Boolean,
            default: true
        },
        searchable: {
            type: Boolean,
            default: true
        },
        groupSelect: {
            type: Boolean,
            default: false
        },
        groupLabel: {
            type: String,
            default: null
        },
        groupValues: {
            type: String,
            default: null
        },
    },
    data(){
        return {
            optionsInitial: [],
            optionsSelected: [],
            optionsToDestroy: []

        }
    },
    beforeMount(){
        this.options.forEach((option)=>{
            if(!('new' in option)){
                option.new = false;
            }
        });
        this.values.forEach(option=>{
            var _optionToInsert = option;
            if(this.valuesMap != null){
                _optionToInsert = this.valuesMap(option);
            }
            if (this.groupSelect && this.groupValues){
                var flatternList = this.options.map(e=>e[this.groupValues]).flat();
                _optionToInsert = flatternList.find(e=>e.value===_optionToInsert.value);
            }
            else {
                _optionToInsert = this.options.find(e=>e.value===_optionToInsert.value);
            }
            this.optionsSelected.push(_optionToInsert);
            this.optionsInitial.push(_optionToInsert);
        });
    },
    computed: {
        selectedPreExistentOptions(){
            return this.optionsSelected.filter(option=>(option.new===false || option.new===undefined));
        },
        selectedNewOptions(){
            return this.optionsSelected.filter(option=>option.new===true);
        },
        mySelectedName(){
            return this.selectedName || this.name;
        },
        mySelectedName(){
            if(typeof this.name === 'string'){
                return this.name;
            } else if(typeof this.name === 'function'){
                return this.name();
            }
        },
    },
    methods: {
        defaultOnAddTag(newTagData){
            if(this.onAddTag != null){
                this.onAddTag(newTagData, this.optionsSelected);
            } else {
                const newTag = {
                    text: newTagData,
                    value: newTagData.substring(0, 2) + Math.floor((Math.random() * 10000000)),
                    new: true
                }
                this.optionsSelected.push(newTag);
            }
        },
        myOnRemove(removedOption, id){
            if(this.onRemove != null){
                this.onRemove(removedOption, id);
            } else {
                console.log({removedOption: removedOption, id: id});
                if(removedOption.new == false && this.isInOptionsInitial(removedOption)){
                    this.optionsToDestroy.push(removedOption);
                }
                console.log(this.optionsToDestroy);
            }
        },
        isInOptionsInitial(option){
            return this.optionsInitial.map(e=>e.value).includes(option.value);
        }
    }
}
</script>
