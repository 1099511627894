<script>
export default {
    install (Vue, options) {
      Vue.prototype.openModal = function(id){
        var modalElement = this.$children.find(child => {return child.$attrs.id == id;});
        modalElement.open()
      }
      Vue.prototype.closeModal = function(id){
        var modalElement = this.$children.find(child => {return child.$attrs.id == id;});
        modalElement.close();
      }
      Vue.prototype.copyTextToClipboard = function(text){
        navigator.clipboard.writeText(text);
      }
      Vue.prototype.submitForm = function(id){
        var form_object = document.getElementById(id);
        form_object.submit()
      }

      Vue.prototype.submitFormEdit = function(id){
        var form_object = document.getElementById(id);
        var submitButton = event.target;
        submitButton.disabled = true;
        submitButton.textContent = submitButton.getAttribute('data-disable-with');
        form_object.submit()
      }
    }
}
</script>