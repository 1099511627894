import Vue from 'vue/dist/vue.esm';
// LOADING GLOBAL COMPONENTS



  
	
	import componentMyMultiSelect from "/var/www/myapp/app/webpacker/javascripts/components/global/myMultiSelect.vue";
	Vue.component("vue-my-multi-select", componentMyMultiSelect);

  
	
	import componentSidebar from "/var/www/myapp/app/webpacker/javascripts/components/global/sidebar.vue";
	Vue.component("vue-sidebar", componentSidebar);

  
	
	import componentMyMoney from "/var/www/myapp/app/webpacker/javascripts/components/global/myMoney.vue";
	Vue.component("vue-my-money", componentMyMoney);

  
	
	import componentModal from "/var/www/myapp/app/webpacker/javascripts/components/global/modal.vue";
	Vue.component("vue-modal", componentModal);

  
	
	import componentDynamicList from "/var/www/myapp/app/webpacker/javascripts/components/global/dynamicList.vue";
	Vue.component("vue-dynamic-list", componentDynamicList);

  
	
	import componentDynamicListItem from "/var/www/myapp/app/webpacker/javascripts/components/global/dynamicListItem.vue";
	Vue.component("vue-dynamic-list-item", componentDynamicListItem);

  
	
	import componentAccordion from "/var/www/myapp/app/webpacker/javascripts/components/global/accordion.vue";
	Vue.component("vue-accordion", componentAccordion);


// LOADING PAGE SPECIFIC COMPONENTS
import companyFormCreateComponent from './admin/company/formCreateComponent';
	Vue.component("vue-company-form-create", companyFormCreateComponent);

import catalogSelectTagComponent from './admin/catalog/selectTag';
	Vue.component('vue-catalog-select-tag', catalogSelectTagComponent);

import catalogFormCreateComponent from './admin/catalog/formCreateComponent';
	Vue.component('vue-catalog-form-create', catalogFormCreateComponent);

import associationFormCreateComponent from './admin/association/formCreateComponent';
	Vue.component('vue-association-form-create', associationFormCreateComponent);

import catalogFormComponent from './catalog/catalogCreateComponent';
	Vue.component('vue-catalog-create', catalogFormComponent);