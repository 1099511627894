<template>
    
</template>

<script>
export default {
    data(){
        return {
           showSegment: false,
           showTechnology: false,
           showTag: false,
           showCompany: true
        }
    },
    props:{
        tagPagePresent: Boolean,
        companyPagePresent: Boolean,
        inputSearch: String
    },

    mounted() {
        if (this.tagPagePresent) {
            this.showCompany = false
            this.showTag = true
            this.$nextTick(() => {
                const element = document.querySelector('.catalogo-scroll');
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        } else if (this.companyPagePresent) {
            this.showCompany = true
            this.showTag = false
            this.$nextTick(() => {
                const element = document.querySelector('.catalogo-scroll');
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        }
    },
    beforeMount(){
    },
    methods:{
        toogleSegment(event){
            this.changeTofalse()
            this.showSegment = true;
        },
        toogleTechnology(event){
            this.changeTofalse()
            this.showTechnology = true;
        },
        toogleTag(event){
            this.changeTofalse()
            this.showTag = true;
        },
        toogleCompany(event){
            this.changeTofalse()
            this.showCompany = true;
        },
        changeTofalse(){
           this.showSegment = false,
           this.showTechnology= false,
           this.showTag = false
           this.showCompany = false
            this.$nextTick(() => {
                const element = document.querySelector('.catalogo-search-scroll');
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        },

        clearTerm(){
            this.inputSearch = ""
        }
    }
}
</script>
