<script>
export default {
    data: function(){
        return {
            opened: true,
        }
    },
    props:{
        start_opened: {
            type: Boolean,
            default: true
        },
        headerIcon:{
            type: String,
            default: "fa fa-chevron-up"
        }
    },
    computed: {
        bodyStyle: function (){
            if(this.isOpen){
                return {};
            } else {
                return {display: 'none'};
            }
        },
        isOpen: function(){
            return this.$data.opened;
        },
        toggleClass: function(){
            if(this.isOpen){
                return 'expanded';
            } else {
                return 'colapsed';
            }
        },
        bodyClass: function(){
            if(this.isOpen){
                return 'expanded';
            } else {
                return 'colapsed';
            }
        }
    },
    methods: {
        toggle(){
            if(this.isOpen == true){
                this.colapse();
            } else {
                this.expand();
            }
        },
        expand(){
            this.setOpened(true);
        },
        colapse(){
            this.setOpened(false);
        },
        setOpened(value){
            this.$data.opened = value;
        }
    },
    beforeMount: function() {
        this.setOpened(this.start_opened);
    }
}
</script>

<template>
    <div accordion-component >
        <div header ui-row @click="toggle" class="space-between">
            <slot name="header"></slot>
            <div :class="toggleClass">
                <i toggle-icon :class="headerIcon" aria-hidden="true"></i>
            </div>
        </div>
        <div body :style="bodyStyle" mt=1>
            <slot name="body" class="colapsed"></slot>
        </div>
    </div>
</template>