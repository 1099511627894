<script>
  export default {
    data () {
      return {
          cleanModal: false,
          showModal: false,
          loadingSubmit: false,
      }
      },
    watch: {
      showModal: {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
            if (newValue) {
                document.body.classList.add('no-scroll');
            }
            if (!newValue) {
                document.body.classList.remove('no-scroll');
            }
        }
      }
    },

    methods: {
      close() {
        this.showModal = false;
      },
      open(){
        this.showModal = true;
      }
    },
    created() {
      const that = this;
      document.addEventListener('keyup', function (evt) {
        if (evt.keyCode === 27) {
          that.close();
        }
      });
    }
  }
</script>

<template>
  <div class="modal-component" :showmodal="showModal">
    <div bg-modal></div>
    <div class="modal-box" ref="modal-page">
      <div header>
        <slot name="header"></slot>
        <span class="icon-close" @click="close">
          <i class="fa fa-times"></i>
        </span>
      </div>
      <div body>
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>