

  import '/var/www/myapp/app/webpacker/images/unilever.png';

  import '/var/www/myapp/app/webpacker/images/technology.png';

  import '/var/www/myapp/app/webpacker/images/cata-logo-softex-1.png';

  import '/var/www/myapp/app/webpacker/images/segment.png';

  import '/var/www/myapp/app/webpacker/images/briefcase-fill.svg';

  import '/var/www/myapp/app/webpacker/images/bg_catalogoo.jpg';

  import '/var/www/myapp/app/webpacker/images/moura.png';

  import '/var/www/myapp/app/webpacker/images/softex-branca.png';

  import '/var/www/myapp/app/webpacker/images/soar/Plameet3.png';

  import '/var/www/myapp/app/webpacker/images/chat-square-text-fill.svg';

  import '/var/www/myapp/app/webpacker/images/selo_associado_softex.png';

  import '/var/www/myapp/app/webpacker/images/jcpm.png';

  import '/var/www/myapp/app/webpacker/images/softex.png';

  import '/var/www/myapp/app/webpacker/images/bar-chart-fill.svg';

  import '/var/www/myapp/app/webpacker/images/softex-logo-color.png';

  import '/var/www/myapp/app/webpacker/images/bradesco.png';

  import '/var/www/myapp/app/webpacker/images/oracle.png';

  import '/var/www/myapp/app/webpacker/images/element-separator-footer.svg';

  import '/var/www/myapp/app/webpacker/images/diagram-3-fill.svg';

  import '/var/www/myapp/app/webpacker/images/catalogo.png';

  import '/var/www/myapp/app/webpacker/images/proposito-logo-horizontal-white-768x132.png';

  import '/var/www/myapp/app/webpacker/images/pdf.png';

  import '/var/www/myapp/app/webpacker/images/associacao.png';

  import '/var/www/myapp/app/webpacker/images/tag.png';

  import '/var/www/myapp/app/webpacker/images/renner.png';

  import '/var/www/myapp/app/webpacker/images/company.png';

  import '/var/www/myapp/app/webpacker/images/inquine.png';

  import '/var/www/myapp/app/webpacker/images/cata-logo-softex-2.png';

  import '/var/www/myapp/app/webpacker/images/encerrar.png';

  import '/var/www/myapp/app/webpacker/images/empresa.png';

  import '/var/www/myapp/app/webpacker/images/suape.png';

  import '/var/www/myapp/app/webpacker/images/cata-logo-softex-2-transformed.png';

  import '/var/www/myapp/app/webpacker/images/proposito-logo-horizontal-color-768x132.png_.png';
