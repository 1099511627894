<script>
export default {
    data(){
        return {
            sidebar_el: true,
        }
        
    },
    methods:{
        openSideM: function(){
            if(window.screen.width >= 992){
                document.getElementById("sidebar").style.width = "20%";
                document.getElementById("messages").style.marginLeft = "20%";
                document.getElementById("app-version").style.display = "flex";
            }else{
                document.getElementById("sidebar").style.width = "100%";
                document.getElementById("messages").style.marginLeft = "0";
                document.getElementById("app-version").style.display = "flex";
            }
            this.sidebar_el = false;
        },
        closeSideM: function(){
            if(window.screen.width >= 992){
                document.getElementById("sidebar").style.width = "0%";
                document.getElementById("messages").style.marginLeft = "0%";
                document.getElementById("messages").style.transition = "0.95s";
                document.getElementById("app-version").style.display = "none";
            }else{
                document.getElementById("sidebar").style.width = "0%";
                document.getElementById("messages").style.marginLeft = "0%";
                document.getElementById("app-version").style.display = "none";
            }
            this.sidebar_el = true;
        },
    },
}
</script>