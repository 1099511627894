<script>
export default {
    data(){
        return {
            hearAbout: '',
            showNewComment: false
        }
    },
    props:{
        initialHearAbout: 'none'
    },
    beforeMount(){
        this.hearAbout = this.initialHearAbout
    },
    methods:{
        onChangeHearAbout (event){
            this.hearAbout = event.target.value
        },
        toogleShowNewComment(event){
            this.showNewComment = true;
        }
    }

}
</script>
